<template>
  <b-row>
    <b-col cols="12">
      <b-modal
          ref="chosePatient"
          id="chosePatient"
          hide-header
          cancel-disabled
          @ok="chose"
          @cancel="close"
          ok-title="Scegli"
          cancel-title="Annulla"
      >
        <h4 class="mb-4">{{ patient_or_clinic === 'patient' ? 'Scegli Paziente' : 'Scegli Clinica' }}</h4>
        <b-row>
          <b-col cols="12" lg="12" class="mb-3">
            <autocomplete
                v-if="patient_or_clinic === 'patient'"
                url="https://admin.niino.it:446/api/patients"
                anchor="name"
                label=""
                :min="3"
                :on-select="getDataAutocomplete"
                :onShouldRenderChild="renderChild"
            >
            </autocomplete>
            <autocomplete
                v-else
                url="https://admin.niino.it:446/api/clinics"
                anchor="name"
                label=""
                :min="3"
                :on-select="getDataAutocompleteClinics"
                :onShouldRenderChild="renderChildClinics"
            >
            </autocomplete>
          </b-col>
        </b-row>
      </b-modal>
      <BaseCard :title="`${trackId? 'Modifica Prenotazione':'Crea Prenotazione'}`">
        <template v-slot:comcode>
          <!-- switch -->
          <b-row>
            <b-col cols="12" :lg="isVectorUser?10:12" class="text-center">
              <b-form-checkbox
                  v-model="model.track_type_one_way"
                  @change="resetWizard" switch size="lg">
                <template v-if="model.track_type_one_way"><i class="mr-2 icon-direction ml-2"></i> Solo Andata</template>
                <template v-else><i class="mr-2 icon-directions"></i> Andata e ritorno</template>
              </b-form-checkbox>
            </b-col>
            <b-col cols="12" lg="2" class="text-center">
              <b-button variant="primary" slot="finish" class="mr-2" v-if="isVectorUser" @click="AcceptTrack">
                Accetta corsa
                <i class="mdi mdi-chevron-right ml-1"></i>
              </b-button>
            </b-col>
          </b-row>
          <form-wizard
              @on-complete="onComplete"
              @on-loading="setLoading"
              @on-validate="handleValidation"
              @on-error="handleErrorMessage"
              color="#1e88e5"
              :title="null"
              :subtitle="null"
              step-size="sm"
              ref="formWizard"
          >
            <!-- Pazienti e servizi -->
            <tab-content
                :before-change="validateFirstStep"
                title="Pazienti e Servizi"
                icon="mdi mdi-account-multiple">
                <b-row>
                  <b-col cols="12" lg="6">
                    <b-form-radio-group
                        v-model="patient_or_clinic"
                        :options="[
                          { text: 'Paziente', value: 'patient' },
                          { text: 'Clinica', value: 'clinic' },
                        ]"
                        name="radio-inline"
                        class="mb-1 mt-2"
                    />

                    <b-row>
                      <b-col cols="12" lg="10">
                        <b-input :readonly="true" v-model="value_selected_autocomplete"></b-input>
                      </b-col>
                      <b-col cols="12" lg="2">
                        <b-button class="mb-3 ml-auto" variant="success" v-b-modal.chosePatient>
                          Scegli
                        </b-button>
                      </b-col>
                    </b-row>
<!--                    <PatientSelect v-if="patient_or_clinic === 'patient'" ref="patientSelect" @setSelect="(value) => model.user_patient_id = value" />-->
<!--                    <ClinicSelect v-if="patient_or_clinic === 'clinic'" ref="clinicSelect" @setSelect="(value) => model.user_clinic_id = value" />-->
                  </b-col>
                  <b-col cols="12" lg="6" class="mt-1">
                    <TypeServiceTag ref="typeServiceTag" @setSelect="(value) => model.types_of_service = value" />
                  </b-col>
                  <b-col cols="12" lg="6">
                    <h6>Vettore</h6>
                    <VectorSelect ref="vectorSelect" @setSelect="(value) => model.user_vector_id = value" />
                  </b-col>
                </b-row>
            </tab-content>
            <!-- Andata -->
            <tab-content
                :before-change="validateSecondStep"
                title="Andata"
                icon="mdi mdi-map-marker">
              <b-row>
                <b-col cols="12" lg="6" class="mb-3">
                  <h6>Data di partenza</h6>
                  <b-form-datepicker
                      id="datepicker-full-width"
                      v-model="model.departure_date"
                      placeholder="Seleziona Data"
                      menu-class="w-100"
                      calendar-width="100%"
                      class="mb-2"
                      :hide-header="false"
                      :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                      today-button
                      label-today-button="Oggi"
                      reset-button
                      label-reset-button="Cancella"
                      close-button
                      label-close-button="Chiudi"
                      locale="it"
                      size="lg"
                  ></b-form-datepicker>
                </b-col>
                <b-col cols="12" lg="6">
                  <h6>Ora di partenza</h6>
                  <VueTimepicker
                      v-model="model.departure_date_time"
                      :minute-interval="10"
                  />
                </b-col>
                <b-col cols="12" lg="6">
                  <h6>Punto di partenza</h6>
                  <GMapAutocompleteDepartureTo ref="GMapAutocompleteDepartureTo" @setPlace="(value) => model.departure_street_to = value" />
                </b-col>
                <b-col cols="12" lg="6">
                  <h6>Punto di arrivo</h6>
                  <GMapAutocompleteDepartureArrive ref="GMapAutocompleteDepartureArrive" @setPlace="(value) => model.departure_street_arrive = value" />
                </b-col>
              </b-row>
            </tab-content>
            <!-- Ritorno -->
            <tab-content
                v-if="!model.track_type_one_way"
                :before-change="validateThirdStep"
                title="Ritorno"
                icon="mdi mdi-map-marker-multiple">
              <b-row>
                <b-col cols="12" lg="6" class="mb-3">
                  <h6>Data del ritorno</h6>
                  <b-form-datepicker
                      id="datepicker-full-width-return"
                      v-model="model.return_date"
                      placeholder="Seleziona Data"
                      menu-class="w-100"
                      calendar-width="100%"
                      :min="new Date(model.departure_date)"
                      class="mb-2"
                      :hide-header="false"
                      :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                      today-button
                      label-today-button="Oggi"
                      reset-button
                      label-reset-button="Cancella"
                      close-button
                      label-close-button="Chiudi"
                      locale="it"
                      size="lg"
                  ></b-form-datepicker>
                </b-col>
                <b-col cols="12" lg="6">
                  <h6>Ora del ritorno</h6>
                  <VueTimepicker
                      v-model="model.return_date_time"
                      :minute-interval="10"
                  />
                </b-col>
                <b-col cols="12" lg="6">
                  <h6>Punto di ritorno</h6>
                  <GMapAutocompleteReturn ref="GMapAutocompleteReturn" @setPlace="(value) => model.return_street = value" />
                </b-col>
                <b-col cols="12" lg="6" class="mt-4 pt-2">
                  <b-checkbox v-model="same_return_from_departure"
                              @change="setSameReturn"
                  >
                    Usa stesso punto di partenza</b-checkbox>
                </b-col>
              </b-row>
            </tab-content>
            <!-- Conferma -->
            <tab-content
                title="Convalida"
                icon="mdi mdi-calendar-check">
              <b-row>
                <b-col cols="12" lg="12" class="mb-3">
                  <b-card-title>{{model.track_type_one_way ? 'Solo Andata': 'Andata e Ritorno'}} </b-card-title>
                  <b-card-title>Data di partenza: {{model.departure_date +' '+ model.departure_date_time}} </b-card-title>
                  <b-card-title>Partenza da: {{model.departure_street_to}} </b-card-title>
                  <b-card-title>Partenza a: {{model.departure_street_arrive}} </b-card-title>
                  <b-card-title v-if="!model.track_type_one_way">Data del ritorno: {{model.return_date +' '+ model.return_date_time}}, punto di ritorno {{model.return_street}} </b-card-title>
                  <b-card-title >Vettore: {{resume_info.vector_name}}, costo per km {{resume_info.vector_cost_by_km}} €</b-card-title>
                  <b-card-title >Tot km: {{resume_info.km}}</b-card-title>
                  <b-card-title>Totale costo (i.e) per km: {{ resume_info.total_cost}} €</b-card-title>
                  <b-card-title>Totale costo (i.i) per km: {{ resume_info.total_cost_with_tax}} €</b-card-title>
                </b-col>
                <b-col cols="12" lg="12" class="mb-3">
                  <h6>Note</h6>
                  <b-form-textarea
                      id="textarea-auto-height"
                      rows="3"
                      max-rows="8"
                      v-model="model.notes"
                  ></b-form-textarea>
                </b-col>
              </b-row>
            </tab-content>

            <div class="loader" v-if="loadingWizard"></div>
            <div v-if="errorMsg">
              <span class="error error--text" style="color: red">{{ errorMsg }}</span>
            </div>

            <b-button variant="danger" dark slot="prev">
              <i class="mdi mdi-chevron-left mr-1"></i>
              Indietro
            </b-button>
            <b-button ref="wizard_next" variant="primary" slot="next">
              Avanti
              <i class="mdi mdi-chevron-right ml-1"></i>
            </b-button>
            <b-button variant="success" slot="finish">
              Salva
              <i class="mdi mdi-check ml-1"></i>
            </b-button>
          </form-wizard>
        </template>
      </BaseCard>
    </b-col>
  </b-row>
</template>

<script>
import {FormWizard, TabContent} from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import VueTimepicker from 'vue2-timepicker'
import 'vue2-timepicker/dist/VueTimepicker.css'
import Autocomplete from 'vue2-autocomplete-js'

export default {
  name: "TrackReservationCreate",
  components: {
    FormWizard,
    TabContent,
    BaseCard: () => import("@/components/card/BaseCard"),
    // PatientSelect: () => import("@/views/patient/components/PatientSelect"),
    VectorSelect: () => import("@/views/users/components/VectorsSelect"),
    // ClinicSelect: () => import("@/views/patient/components/ClinicsSelect"),
    TypeServiceTag: () => import("@/views/type-of-services/components/Select"),
    GMapAutocompleteDepartureTo: () => import("@/components/gmaps/Autocomplete"),
    GMapAutocompleteDepartureArrive: () => import("@/components/gmaps/Autocomplete"),
    GMapAutocompleteReturn: () => import("@/components/gmaps/Autocomplete"),
    VueTimepicker,
    Autocomplete
  },
  props: ['trackId', 'date'],
  computed: {
    user(){
      return this.$store.state.authentication;
    }
  },
  data() {
    // let now;
    // if (this.date) {
    //   now = this.$moment(this.date);
    // } else {
    //   now = this.$moment(this.date);
    // }
    return {
      go: true,
      patient_or_clinic: 'patient',
      loadingWizard: false,
      errorMsg: null,
      total_cost: 0,
      resume_info: {},
      isVectorUser: false,
      same_return_from_departure: false,
      value_selected_autocomplete: '',
      model: {
        id: '',
        track_type_one_way: true,
        user_patient_id: 0,
        user_clinic_id: 0,
        user_vector_id: 0,
        types_of_service: '',
        departure_date: null,
        departure_date_time: null,
        departure_street_to: '',
        departure_street_arrive: '',
        return_date: '',
        return_date_time: '',
        return_street: '',
        notes: '',
      }
    }
  },
  methods: {
    renderChildClinics(data) {
      return `
      <span>${data.company_name}</span>
    `
    },
    renderChild(data) {
      return `
      <span>${data.name}</span>
    `
    },
    getDataAutocompleteClinics(obj){
      if (this.patient_or_clinic === 'patient') {
        this.model.user_patient_id = obj.id
        this.model.user_clinic_id = null
      } else {
        this.model.user_patient_id = null
        this.model.user_clinic_id = obj.id
      }

      this.value_selected_autocomplete = obj.company_name
    },
    getDataAutocomplete(obj){
      if (this.patient_or_clinic === 'patient') {
        this.model.user_patient_id = obj.id
        this.model.user_clinic_id = null
      } else {
        this.model.user_patient_id = null
        this.model.user_clinic_id = obj.id
      }

      this.value_selected_autocomplete = obj.name
    },
    close() {
      this.$refs["chosePatient"].hide();
    },
    chose() {
      this.$refs["chosePatient"].hide();
    },
    setSameReturn() {
      if(this.same_return_from_departure) {
        this.$refs.GMapAutocompleteReturn.$refs[`gmaps_ref`].$refs.input.value = this.model.departure_street_to
        this.model.return_street = this.model.departure_street_to
        this.$refs.GMapAutocompleteReturn.gmaps_address = this.model.departure_street_to
      } else {
        this.$refs.GMapAutocompleteReturn.$refs[`gmaps_ref`].$refs.input.value = ''
        this.model.return_street = ''
        this.$refs.GMapAutocompleteReturn.gmaps_address = ''
      }
    },
    checkMinTime: function () {
      // if (this.model.departure_date === ( (new Date()).getDay()+'/'+(new Date()).getMonth()+'/'+(new Date()).getFullYear() ) ) {
      //
      // }
    },
    async AcceptTrack() {
      const res = await this.axios.post(
          `track-reservation/${this.model.id}/vector/${this.model.user_vector_id}/accept`
      );
      this.$swal.fire({
        title: 'Accettazione Corsa!',
        text: res.data.message,
        icon: res.data.status,
        confirmButtonColor: '#1f2335',
      })
    },
    setLoading: function (value) {
      this.loadingWizard = value;
    },
    handleValidation: function (isValid, tabIndex) {
      this.selected_tab_wizard = tabIndex;
      if ((tabIndex+1) > 0) {
        this.checkbox_caregiver = false
      } else {
        this.checkbox_caregiver = false
      }
      console.log("Tab: " + tabIndex + " valid: " + isValid);
    },
    handleErrorMessage: function (errorMsg) {
      this.errorMsg = errorMsg;
    },
    validateFirstStep: function () {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          if (!this.model.user_patient_id || !this.model.user_vector_id) {
            console.log(this.model)
            reject(
                "Inserisci un paziente/clinica e un servizio *"
            );
          } else {
            resolve(true);
          }
        }, 300);
      });
    },
    validateSecondStep: function () {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          if (!this.model.departure_date || !this.model.departure_date_time || !this.model.departure_street_to || !this.model.departure_street_arrive) {
            reject(
                "Inserisci i campi obbligatori evidenziati con *"
            );
          } else {
            this.getDistance()
            resolve(true);
          }
        }, 300);
      });
    },
    validateThirdStep: function () {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          if (!this.model.return_date || !this.model.return_date_time || !this.model.return_street) {
            reject(
                "Inserisci i campi obbligatori evidenziati con *"
            );
          } else {
            this.getDistance()
            resolve(true);
          }
        }, 300);
      });
    },
    validateFourthStep: function () {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          if (!this.go) {
            reject(
                "Inserisci i campi obbligatori evidenziati con *"
            );
          } else {
            resolve(true);
          }
        }, 300);
      });
    },
    resetWizard() {
      this.$refs.formWizard.reset()
    },
    async onComplete(evt) {
      console.log(evt)
      const res = await this.axios.post('track-reservation', this.model)
      this.$swal.fire({
        title: 'Salvataggio Prenotazione',
        text: res.data.message,
        icon: res.data.status,
        confirmButtonColor: '#1f2335',
      })
      if (res.data.status === 'success') {
        await this.$router.push('/track-reservations/new')
      }
    },
    async getDistance() {
      if (this.model.departure_street_to === undefined || this.model.departure_street_arrive === undefined) {
        this.$swal.fire({
          type: 'error',
          title: 'Oops...',
          text: 'Sembra che mancano uno o piu campi obbligatori, prova ad inserire la data e luogo di partenza.',
        })
      } else {
        const origin = this.model.departure_street_to
        const destination = this.model.departure_street_arrive
        const return_data = this.model.return_street
        const response = await this.axios.get(`track-reservations/get-distance-matrix?origins=${origin}&destinations=${destination}&return_data=${return_data}&vector_id=${this.model.user_vector_id}&service_id=${this.model.types_of_service}`)

        this.resume_info = response.data
        this.resume_info.notes = this.model.notes
        this.model.finalStep = this.resume_info;
        this.model.distance_matrix_response = this.resume_info;
      }
    },

    async getElementInfo() {
      if (this.trackId) {
        const res = await this.axios.get('track-reservation/'+this.trackId)
        const departure_date_time = new Date(res.data.departure_date)
        const return_datetime = res.data.return_date? new Date(res.data.return_date) : false

        this.model.id = res.data.id
        this.model.track_type_one_way = res.data.track_type !== 'round_trip'
        this.model.user_patient_id = res.data.patient?res.data.patient.id:null
        this.model.user_clinic_id = res.data.clinic?res.data.clinic.id:null
        this.model.user_vector_id = res.data.vector.id
        this.model.types_of_service = res.data.types_of_service||''
        console.log('get info ::: ', departure_date_time)
        this.model.departure_date = departure_date_time.getFullYear()+'-'+(departure_date_time.getMonth()+1).toString().padStart(2, '0')+'-'+departure_date_time.getDate().toString().padStart(2, '0')
        this.model.departure_date_time = departure_date_time.getHours().toString().padStart(2, '0')+':'+departure_date_time.getMinutes().toString().padStart(2, '0')
        this.model.departure_street_to = res.data.departure_street_to
        if (this.model.departure_street_to && this.$refs.GMapAutocompleteDepartureTo) {
          this.$refs.GMapAutocompleteDepartureTo.$refs[`gmaps_ref`].$refs.input.value = this.model.departure_street_to
          this.$refs.GMapAutocompleteDepartureTo.gmaps_address = this.model.departure_street_to
        }
        this.model.departure_street_arrive = res.data.departure_street_arrive
        if (this.model.departure_street_arrive && this.$refs.GMapAutocompleteDepartureArrive) {
          this.$refs.GMapAutocompleteDepartureArrive.$refs[`gmaps_ref`].$refs.input.value = this.model.departure_street_arrive
          this.$refs.GMapAutocompleteDepartureArrive.gmaps_address = this.model.departure_street_arrive
        }

        this.model.return_date = return_datetime?return_datetime.getFullYear()+'-'+(return_datetime.getMonth()+1).toString().padStart(2, '0')+'-'+return_datetime.getDate().toString().padStart(2, '0'):''
        this.model.return_date_time = return_datetime?return_datetime.getHours().toString().padStart(2, '0')+':'+return_datetime.getMinutes().toString().padStart(2, '0'):''

        this.model.return_street = res.data.return_street||''
        if (this.model.return_street && this.$refs.GMapAutocompleteReturn) {
          this.$refs.GMapAutocompleteReturn.$refs[`gmaps_ref`].$refs.input.value = this.model.return_street
          this.$refs.GMapAutocompleteReturn.gmaps_address = this.model.return_street
        }

        this.same_return_from_departure = this.model.return_street === this.model.departure_street_to
        this.model.notes = res.data.notes||''

        if (this.model.user_clinic_id) {
          this.patient_or_clinic = 'clinic'
          this.$refs.clinicSelect.value = this.model.user_clinic_id
        } else {
          this.patient_or_clinic = 'patient'
          this.$refs.patientSelect.value = this.model.user_patient_id
        }

        if (this.model.types_of_service) {
          this.$refs.typeServiceTag.value = this.model.types_of_service
        }

      }
    }
  },
  watch: {
    'model.departure_date'(newVal){
      if (!this.trackId) {
        this.model.return_date = newVal
      }
    },
    'model.departure_date_time'(newVal){
      if (!this.trackId) {
        this.model.return_date_time = newVal
      }
    },
    async trackId() {
      setTimeout( async() => await this.getElementInfo(), 300 )
    }
  },
  async beforeMount() {
    setTimeout( async() => await this.getElementInfo(), 300 )
  },
  mounted() {
    const that = this
    setTimeout(function () {
      if (that.user.user_rule === 'vector') {
        that.isVectorUser = true
        that.$refs.vectorSelect.value = that.user.user.id
        that.$refs.vectorSelect.disable = true
        that.model.user_vector_id = that.user.user.id
      } else {
        that.model.user_vector_id = 1
        that.$refs.vectorSelect.value = 1
      }
    }, 400)
  }
}
</script>

<style>
.vue__time-picker input.display-time {
  height: 3.1em !important;
  border-radius: 3px !important;
}
</style>